import { Dispatch, FunctionComponent, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CustomButton from '../../components/customButton'
import { useAppSelector } from '../../config/hooks'
import LineArrow from '../../icons/lineArrow'
import { UserOtherMetaRoles } from '../../models/userRoles'
import { postDownloadEvent } from '../../store/dashboard.slice'
import { IAiModelCardProps } from '../aiModelCard'
import InstallationDialog from './installationDialog'

const SolutionDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding-bottom: 2rem;
    padding-top: 1rem;

    width: 100%;
    @media screen and (min-width: 768px) {
        padding-top: 0;
        width: 275px;
        min-width: 275px;
        max-width: 275px;
        padding-right: 1rem;
    }
    @media screen and (min-width: 1280px) {
        width: 340px;
        min-width: 340px;
        max-width: 340px;
    }
    @media screen and (min-width: 1536px) {
        width: 430px;
        min-width: 430px;
        max-width: 430px;
    }
`

const SolutionImage = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 1rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
`

const BackLink = styled(Link)`
    color: ${(props) => props.theme.color.text.navy};
    margin-bottom: 1rem;
    svg {
        transform: scale(0.6) rotate(180deg);
        margin-right: 0.25rem;
    }
`

const Title = styled.h2`
    font-weight: 300;
    color: ${(props) => props.theme.color.text.navy};
`

const Contributors = styled.span`
    font-weight: 500;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
    color: ${(props) => props.theme.color.text.navy};
`

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        postDownloadEvent: (data: any) => dispatch(postDownloadEvent(data)),
    }
}

const mapStateToProps = () => ({})

const connector = connect(mapStateToProps, mapDispatchToProps)
type ReduxProps = ConnectedProps<typeof connector>

export type SolutionDetailProps = ReduxProps & {
    model: IAiModelCardProps
    setActiveTab: (tab: string) => void
}

const SolutionDetail: FunctionComponent<SolutionDetailProps> = ({
    model,
    setActiveTab,
    postDownloadEvent,
}: SolutionDetailProps) => {
    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )
    let isAnalyst = false

    const [showInstallationModal, setShowInstallationModal] = useState(false)

    if (authPermissionsResult?.otherMetaPermissions) {
        let metaPermissions =
            authPermissionsResult.otherMetaPermissions.toString()
        if (metaPermissions) {
            metaPermissions = metaPermissions.split(',')
        }
        isAnalyst = metaPermissions.includes(UserOtherMetaRoles.Analyst)
    }

    const repositoryLink = model?.repository ? JSON.parse(model.repository) : {}

    const onGetDeploy = () => {
        window.open(
            '/explore/deploy?solution=' + model.name,
            '_self',
            'noopener'
        )
    }

    const onShareLinkClick = () => {
        let shareLinkURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        navigator.clipboard.writeText(shareLinkURL)
        alert('Link copied to clipboard: ' + shareLinkURL)
    }

    const onDownloadClick = () => {
        postDownloadEvent({ model: model.name })
        setShowInstallationModal(true)
    }

    const onInstallationDialogClose = () => {
        setShowInstallationModal(false)
    }

    const getContributors = () => {
        if (
            model.tags?.PoweredBy &&
            Array.isArray(model.tags?.PoweredBy) &&
            model.tags?.PoweredBy.length > 0
        ) {
            return (
                <Contributors>
                    Powered by {model.tags?.PoweredBy.join(' / ')}
                </Contributors>
            )
        }
        return null
    }

    return (
        <>
            <SolutionDetailsWrapper>
                <BackLink to="/explore">
                    <LineArrow blue />
                    Back to catalog
                </BackLink>
                <SolutionImage
                    style={{ backgroundImage: `url('${model.logo}')` }}
                />
                <Title>{model.displayName}</Title>
                {getContributors()}
                <p>{model.description}</p>
                {model?.tags?.['Compare']?.includes('deploy') && isAnalyst && (
                    <CustomButton
                        text="Deploy"
                        styleOverride={{
                            marginRight: 'auto',
                            marginBottom: '1rem',
                        }}
                        onClick={onGetDeploy}
                    />
                )}
                <CustomButton
                    icon="download"
                    text="Download installation instructions"
                    wrap={true}
                    styleOverride={{
                        marginRight: 'auto',
                        marginBottom: '1rem',
                    }}
                    onClick={onDownloadClick}
                />

                {repositoryLink.name && repositoryLink.link && (
                    <CustomButton
                        icon="viewOn"
                        text={`View on ${repositoryLink.name}`}
                        styleOverride={{
                            marginRight: 'auto',
                            marginBottom: '1rem',
                        }}
                        onClick={() => {
                            window.open(repositoryLink.link)
                        }}
                    />
                )}

                <CustomButton
                    icon="share"
                    text={'Share'}
                    styleType="darkOutline"
                    styleOverride={{
                        marginRight: 'auto',
                        marginBottom: '1rem',
                    }}
                    onClick={onShareLinkClick}
                />
            </SolutionDetailsWrapper>
            <InstallationDialog
                show={showInstallationModal}
                model={model}
                onClose={onInstallationDialogClose}
            />
        </>
    )
}

export default connector(SolutionDetail)
