export const modelOverviewData = [
    {
        model: 'teknium/openhermes-2.5-mistral-7b',
        author: 'Intel',
        displayName: 'ChatQnA Application',
        overview: `Chatbots are the most widely adopted use case for leveraging the powerful chat and reasoning capabilities of large language models (LLMs). The retrieval augmented generation (RAG) architecture is quickly becoming the industry standard for chatbots development. It combines the benefits of a knowledge base (via a vector store) and generative models to reduce hallucinations, maintain up-to-date information, and leverage domain-specific knowledge.
\n RAG bridges the knowledge gap by dynamically fetching relevant information from external sources, ensuring that responses generated remain factual and current. The core of this architecture are vector databases, which are instrumental in enabling efficient and semantic retrieval of information. These databases store data as vectors, allowing RAG to swiftly access the most pertinent documents or data points based on semantic similarity.`,
        logo: '/aiModelImage/neural.webp',
    },
    {
        model: 'rag-intel/neural-chat-7b-v3-3',
        author: 'Intel',
        displayName: 'ChatQnA Application',
        overview: `Chatbots are the most widely adopted use case for leveraging the powerful chat and reasoning capabilities of large language models (LLMs). The retrieval augmented generation (RAG) architecture is quickly becoming the industry standard for chatbots development. It combines the benefits of a knowledge base (via a vector store) and generative models to reduce hallucinations, maintain up-to-date information, and leverage domain-specific knowledge.
\n RAG bridges the knowledge gap by dynamically fetching relevant information from external sources, ensuring that responses generated remain factual and current. The core of this architecture are vector databases, which are instrumental in enabling efficient and semantic retrieval of information. These databases store data as vectors, allowing RAG to swiftly access the most pertinent documents or data points based on semantic similarity.`,
        logo: '/aiModelImage/microsoft_phi.jpg',
    },
    {
        model: 'rag-intel/chat',
        author: 'Intel',
        displayName: 'Intel® AI for Enterprise RAG',
        overview: `Enterprises are increasingly utilizing large language models (LLMs) for their sophisticated GenAI needs specifically around conversational abilities. The Retrieval-Augmented Generation (RAG) architecture integrates the strengths of knowledge bases through vector storage with the creative potential of generative models.
        \nAs the RAG techniques advance, sophisticated document processing techniques for vector database ingestion such as semantic segmentation and hierarchical indexing further  refine the quality of generated outcomes. Being able to deploy the application across multiple users, user personas and language used pose another challenge as AI pipelines are built.`,
        logo: '/aiModelImage/microsoft_phi.jpg',
    },
    {
        model: 'stabilityai/stable-diffusion-2-base',
        author: 'stabilityai',
        displayName: 'StabilityAi/Stable-Diffusion',
        overview:
            'A fast generative text-to-image model that takes a natural language description as input and generates an image matching that description.',
        logo: '/aiModelImage/image_generator.png',
    },
    {
        model: 'whisper-llm',
        author: 'Intel',
        displayName: 'AudioQnA',
        overview: `AudioQnA serves like a talking bot, enabling LLMs to talk with users. It basically accepts users' audio inputs, converts to texts and feed to LLMs, gets the text answers and converts back to audio outputs.\n

What AudioQnA is delivering and why it stands out:\n

Fast ASR/TTS inference as microservices on Intel Xeon CPUs with optimization\n
Multilingual Zero-shot voice cloning cross languages, customizable voice\n
Fast LLM inference on Intel Gaudi through TGI with RAG and other features support`,
        logo: '/aiModelImage/audio2text.png',
    },
    {
        model: 'docusum-intel/neural-chat-7b-v3-3',
        author: 'Intel',
        displayName: 'Document Summarization Application',
        overview: `In a world where data, information, and legal complexities are prevalent, the volume of legal documents is growing rapidly. Law firms, legal professionals, and businesses are dealing with an ever-increasing number of legal texts, including contracts, court rulings, statutes, and regulations. These documents contain important insights, but understanding them can be overwhelming. This is where the demand for legal document summarization comes in.\n

Large Language Models (LLMs) have revolutionized the way we interact with text. These models can be used to create summaries of news articles, research papers, technical documents, and other types of text. Suppose you have a set of documents (PDFs, Notion pages, customer questions, etc.) and you want to summarize the content. In this example use case, we utilize LangChain to implement summarization strategies and facilitate LLM inference using Text Generation Inference on Intel Xeon and Gaudi2 processors.`,
        logo: '/aiModelImage/text-summary.png',
    },
    {
        model: 'rag-meta/llama-70b',
        author: 'meta',
        displayName: 'Llama-70b',
        overview:
            'Advanced state-of-the-art LLM with language understanding, superior reasoning, and text generation.',
        logo: '/aiModelImage/lama7b.webp',
    },
    {
        model: 'tgi-embed',
        author: 'sentence-transformers',
        displayName: 'all-MiniLM-L6-v2',
        overview:
            'all-MiniLM-L6-v2 maps sentences and paragraphs to a 384 dimensional dense vector space and can be used for tasks like clustering or semantic search.',
        logo: '/aiModelImage/microsoft_phi.jpg',
    },
    {
        model: 'llava-hf/llava-1.5-7b-hf',
        author: 'Intel',
        displayName: 'Visual Question and Answering',
        overview: `Visual Question Answering (VQA) is the task of answering open-ended questions based on an image. The input to models supporting this task is typically a combination of an image and a question, and the output is an answer expressed in natural language.

Some noteworthy use case examples for VQA include:\n

Accessibility applications for visually impaired individuals.\n
Education: posing questions about visual materials presented in lectures or textbooks. VQA can also be utilized in interactive museum exhibits or historical sites.\n
Customer service and e-commerce: VQA can enhance user experience by letting users ask questions about products.
Image retrieval: VQA models can be used to retrieve images with specific characteristics. For example, the user can ask “Is there a dog?” to find all images with dogs from a set of images.`,
        logo: '/aiModelImage/visual_OIP.jpg',
    },
    {
        model: 'deepseek-ai/deepseek-coder-33b-instruct',
        author: 'Intel',
        displayName: 'OPEA CodeGen Solution',
        overview:
            'Code Generation (CodeGen) Large Language Models (LLMs) are specialized AI models designed for the task of generating computer code.',
        logo: '/aiModelImage/codegen.png',
    },
    {
        model: 'haoranxu/ALMA-13B',
        author: 'Intel',
        displayName: 'Translation Application',
        overview: `Language Translation is the communication of the meaning of a source-language text by means of an equivalent target-language text.`,
        logo: '/aiModelImage/translator.png',
    },
    {
        model: 'rag-meta/llama-7b',
        author: 'meta',
        displayName: 'Llama-7b',
        overview:
            'This model is a fine-tuned 7B parameter LLM on the Intel Gaudi 2 processor from the meta/llama-7b on the meta-math/MetaMathQA dataset.',
        logo: '/aiModelImage/llama2-blue.png',
    },
    {
        model: 'stable-video-diffusion',
        author: 'stabilityai',
        displayName: 'stable-video-diffusion',
        overview:
            'Stable Video Diffusion (SVD) is a generative diffusion model that leverages a single image as a conditioning frame to synthesize video sequences.',
        logo: '/aiModelImage/microsoft_phi.jpg',
    },
    {
        model: 'gemma-7b',
        author: 'google',
        displayName: 'gemma-7b',
        overview:
            'Cutting-edge text generation model text understanding, transformation, and code generation.',
        logo: '/aiModelImage/gem-google.png',
    },
    {
        model: 'codegemma-7b',
        author: 'google',
        displayName: 'codegemma-7b',
        overview:
            "Cutting-edge model built on Google's Gemma-7B specialized for code generation and code completion.",
        logo: '/aiModelImage/google_gem.webp',
    },
    {
        model: 'mistral-large',
        author: 'mistralai',
        displayName: 'mistral-large',
        overview:
            'Excels in complex multilingual reasoning tasks, including text understanding, and code generation.',
        logo: '/aiModelImage/mistralai.avif',
    },
    {
        model: 'recurrentgemma-2b',
        author: 'google',
        displayName: 'recurrentgemma-2b',
        overview:
            'Novel recurrent architecture based language model for faster inference when generating long sequences.',
        logo: '/aiModelImage/gem-google.png',
    },
    {
        model: 'mixtral-8x22b-instruct-v0.1',
        author: 'mistralai',
        displayName: 'mixtral-8x22b-instruct-v0.1',
        overview:
            'An MOE LLM that follows instructions, completes requests, and generates creative text.',
        logo: '/aiModelImage/mistralai.avif',
    },
]
