import { Fragment, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAppSelector } from '../config/hooks'
import { _setEncryptedValueInStorage } from '../lib/encryptStorage'
import { UserOtherMetaRoles } from '../models/userRoles'
import UserProfilePanel from './userProfile'

const SideLinkPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    color: ${(props) => props.theme.color.text.navy};
`

const SideLinkPanelDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0 2rem 0;

    @media screen and (min-width: 768px) {
        padding: 4rem 2rem 2rem 0;
        min-width: 200px;
    }
`

const StyledNavLink = styled(Link)`
    font-family: 'IntelOne Display';
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.7rem;
    text-decoration: none;
    padding-left: 0.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: ${(props) => props.theme.color.text.navy};
    border-left: 6px solid transparent;

    &.active {
        border-left: 6px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
    &:hover {
        font-weight: 500;
    }
    &:disabled {
        color: #00a3fb;
    }
`
const ChildNavLink = styled(StyledNavLink)`
    font-size: 1rem;
    margin: 0 0 0 1rem;
    height: 2.5rem;
`

const ChildLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
`

export interface ISideLinkPanel {
    isUserLoggedIn?: boolean
}

export const SideLinkPanel: FunctionComponent<ISideLinkPanel> = ({
    isUserLoggedIn,
}: ISideLinkPanel) => {
    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )

    let isIntelEmployee = false
    let isAnalyst = false

    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    if (authPermissionsResult?.otherMetaPermissions) {
        let metaPermissions =
            authPermissionsResult.otherMetaPermissions.toString()
        _setEncryptedValueInStorage('otherMetaPermissions', metaPermissions)
        if (metaPermissions) {
            metaPermissions = metaPermissions.split(',')
        }
        isAnalyst = metaPermissions.includes(UserOtherMetaRoles.Analyst)
    }

    if (authPermissionsResult?.userDisplayName) {
        localStorage.setItem(
            'userDisplayName',
            authPermissionsResult?.userDisplayName ?? ''
        )
    }

    interface MenuItem {
        href: string
        label: string
        activeState: string
        subItems?: MenuItem[]
        condition?: boolean
    }

    const menuItems: MenuItem[] = [
        { href: '/', label: 'Home', activeState: 'isHomeActive' },
        {
            href: '/explore',
            label: 'Catalog',
            activeState: 'isDiscoverActive',
            subItems: [
                {
                    href: '/explore/chat',
                    label: 'Chat',
                    activeState: 'isEnterpriseRagActive',
                },
                // {
                //     href: '/explore/retrieval',
                //     label: 'Retrieval',
                //     activeState: 'isRetrievalActive',
                // },
                {
                    href: '/explore/summarization',
                    label: 'Summarization',
                    activeState: 'isSummarizationActive',
                },
                {
                    href: '/explore/visual',
                    label: 'Visual',
                    activeState: 'isVisualActive',
                },
                {
                    href: '/explore/audio',
                    label: 'Audio',
                    activeState: 'isAudioActive',
                },
                {
                    href: '/explore/translation',
                    label: 'Translation',
                    activeState: 'isTranslationActive',
                },
                {
                    href: '/explore/reasoning',
                    label: 'Reasoning',
                    activeState: 'isReasoningActive',
                },
            ],
        },
        // {
        //     href: '/explore/compare',
        //     label: 'Compare',
        //     activeState: 'isCompareActive',
        // },
        {
            href: '/explore/deploy',
            label: 'Deploy',
            activeState: 'isComposeActive',
            condition: isAnalyst,
        },
        // {
        //     href: '/access',
        //     label: 'Access',
        //     activeState: 'isAccessActive',
        //     condition:
        //         isIntelEmployee && location.pathname.indexOf('/access') > -1,
        // },
        {
            href: '/dashboard',
            label: 'Dashboard',
            activeState: 'isDashboardActive',
            condition: isAnalyst,
        },
    ]

    let activeStates: { [key: string]: boolean } = {
        isHomeActive: false,
        isDiscoverActive: false,
        isRetrievalActive: false,
        isCompareActive: false,
        isVisualActive: false,
        isSummarizationActive: false,
        isTranslationActive: false,
        isAudioActive: false,
        isComposeActive: false,
        isReasoningActive: false,
        isAccessActive: false,
        isDashboardActive: false,
        isEnterpriseRagActive: false,
    }

    // Update active states based on the current pathname
    menuItems.forEach((item) => {
        if (item.subItems) {
            // Check subItems first for more specific matches
            item.subItems.forEach((subItem) => {
                if (location.pathname === subItem.href) {
                    activeStates[subItem.activeState] = true
                    activeStates[item.activeState] = false // Ensure parent is not active when child is active
                }
            })
        }
        // Check parent item if no child items matched
        if (!activeStates[item.activeState]) {
            activeStates[item.activeState] = location.pathname === item.href
        }
    })

    return (
        <SideLinkPanelContainer>
            <SideLinkPanelDiv>
                {menuItems.map(
                    ({ label, href, subItems, condition, activeState }) =>
                        condition !== false && (
                            <Fragment key={href}>
                                <StyledNavLink
                                    to={href}
                                    className={
                                        activeStates[activeState]
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    {label}
                                </StyledNavLink>

                                {subItems && (
                                    <ChildLinkContainer>
                                        {subItems.map(
                                            ({ label, href, activeState }) => (
                                                <ChildNavLink
                                                    key={href}
                                                    to={href}
                                                    className={
                                                        activeStates[
                                                            activeState
                                                        ]
                                                            ? 'active'
                                                            : ''
                                                    }
                                                >
                                                    {label}
                                                </ChildNavLink>
                                            )
                                        )}
                                    </ChildLinkContainer>
                                )}
                            </Fragment>
                        )
                )}
            </SideLinkPanelDiv>
            <UserProfilePanel mobile={true} isUserLoggedIn={isUserLoggedIn} />
        </SideLinkPanelContainer>
    )
}

export default SideLinkPanel
