/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { Dispatch, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import ContainerWrapper from '../layouts/containerWrapper'

import SearchGroupComponent from '../components/searchGroupComponent'

import Cloud from '../icons/cloud'
import Hexagon from '../icons/hexagon'
import Rocket from '../icons/rocket'

import CustomButton from '../components/customButton'
import CardLinks from '../layouts/cardLinks'

const HeroBladeWrapper = styled.div`
    background-image: url('/marketing-hero-bg.png');

    background-position: top right;
    background-repeat: no-repeat;
    background-color: #061f4d;
    background-size: 160%;

    @media screen and (min-width: 400px) {
        background-size: 200%;
    }

    @media screen and (min-width: 768px) {
        padding: 40px 24px;
        background-size: 90%;
    }

    @media screen and (min-width: 1100px) {
        background-size: 70%;
    }

    @media screen and (min-width: 1400px) {
        background-size: 50%;
        padding: 80px 48px;
    }
`

const HeroBladeContent = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;

    > div {
        width: 100%
        color: #fff;
    }

    @media screen and (min-width: 768px) {
        flex-direction: column;
        margin: 0 auto;
    }

    @media screen and (min-width: 1400px) {
        flex-direction: row;
        max-width: 1200px;
        margin: 0 auto;
    }
`

const SearchContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0;
    align-items: center;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: center;
    }

    @media screen and (min-width: 1400px) {
        margin-left: 3rem;
        flex-direction: row;
        justify-content: space-between;
    }
`

const HeroCopy = styled.div`
    font-family: 'IntelOne Display';
    font-smoothing: antialiased;
    text-align: left;

    padding: 2rem;

    h1 {
        font-weight: 300;
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
    h4 {
        font-size: 1.5rem;
        font-weight: 300;
    }

    @media screen and (min-width: 768px) {
        padding: 0;
        text-align: center;
        margin-bottom: 2rem;
    }

    @media screen and (min-width: 1400px) {
        margin-bottom: 0;
        text-align: left;
        max-width: 60%;
    }
`

const OrCopy = styled.div`
    margin: 1rem;
    @media screen and (min-width: 768px) {
        margin: 0 1rem;
    }
`
const SeeAll = styled.button`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;
    padding: 0;
    border-radius: 0;
    margin-bottom: 2rem;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
        margin: 0;
    }

    background-color: ${(props) => props.theme.color.text.brightBlue};
    border: ${(props) => props.theme.color.text.darkNavy};

    a {
        padding: 0.5rem 2rem;
        color: ${(props) => props.theme.color.text.darkNavy};
        text-decoration: none;
    }

    &:hover {
        background-color: ${(props) => props.theme.color.text.navy};
        a {
            color: #fff;
            text-decoration: none;
        }
    }

    &.dark {
        background-color: ${(props) => props.theme.color.text.navy};
        border: ${(props) => props.theme.color.text.darkNavy};

        a {
            color: #fff;
            text-decoration: none;
        }

        &:hover {
            background-color: ${(props) => props.theme.color.text.brightBlue};
            a {
                color: #fff;
                text-decoration: none;
            }
        }
    }

    &:not(.dark):hover {
        line,
        path {
            stroke: #fff;
        }
    }
`

const CalloutsBlade = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    background: #fff;

    h2 {
        color: ${(props) => props.theme.color.text.blue};
        font-size: 2rem;
        font-weight: 300;
        margin-bottom: 1rem;
        text-align: center;
    }

    h3 {
        color: ${(props) => props.theme.color.text.blue};
        font-weight: 300;
    }

    p {
        font-family: 'IntelOne Text';
        font-weight: 400;
        text-align: center;
    }
`

const ThreeCol = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    div {
        width: 100%;
    }

    @media screen and (min-width: 900px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        div {
            width: 50%;
        }
    }

    @media screen and (min-width: 1280px) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        div {
            width: 33.3%;
        }
    }
`
const ColumnCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 5px;
    padding: 1rem;
    align-items: center;

    h3 {
        margin: 1rem 0 1rem 0;
        font-size: 1.75rem;
        color: ${(props) => props.theme.color.text.blue};
        text-align: center;
    }
    p {
        margin: 0;
        text-align: center;
    }

    div {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: start;

        h3 {
            margin: 0 0 1rem 0;
            text-align: left;
        }

        p {
            text-align: left;
        }
    }
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    max-width: 160px;
    height: 160px;
    max-height: 160px;
    margin-right: 1rem;
`

const TrendingSolutions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 0;
    padding: 0 2rem 2rem;
    background-color: #f9f9f9;
    h3 {
        margin: 0;
    }
`

const DocumentationBlade = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px;
    background: #fff;

    h2 {
        text-align: center;
        margin: 0 0 1rem 0;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;

        h2 {
            margin: 0 1rem 0 0;
            text-align: left;
        }
    }
`

const DocButton = styled.button`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-decoration: none;

    background-color: #fff;
    padding: 0.5rem 2rem;
    border-radius: 0;
    border: 1px solid ${(props) => props.theme.color.text.blue};

    margin: 0 0 1rem 0;

    a {
        color: ${(props) => props.theme.color.text.blue};
        text-decoration: none;
    }

    &:hover {
        background-color: ${(props) => props.theme.color.text.blue};
        a {
            color: #fff;
            text-decoration: none;
        }
    }

    @media screen and (min-width: 768px) {
        margin: 0 0 0 1rem;
    }
`

const ContributorsContent = styled.div`
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    gap: 3rem;

    & a {
        display: inline-block;
        width: 15%;
    }

    & img {
        width: 100%;
        aspect-ratio: 3 / 2;
        object-fit: contain;
    }

    @media screen and (max-width: 768px) {
        & a {
            width: 25%;
            margin-inline: auto;
        }
    }

    @media screen and (max-width: 440px) {
        flex-direction: column;

        & a {
            width: 66%;
            margin-inline: auto;
        }
    }
`

export interface IMarketingContainerProps {}

const MarketingContainer: FunctionComponent<
    IMarketingContainerProps
> = ({}: IMarketingContainerProps) => {
    const bodyContent = (isUserLoggedIn: boolean) => {
        return (
            <>
                <HeroBladeWrapper>
                    <HeroBladeContent>
                        <HeroCopy>
                            <h1>Deploy GenAI and RAG at Scale</h1>
                            <h4>
                                Intel AI&reg; for Enterprise RAG solutions offer
                                a comprehensive approach to implementing and
                                scaling Retrieval-Augmented Generation (RAG) in
                                your organization. Reduce latency, scale with
                                ease, and benefit from an open ecosystem that
                                seamlessly integrates with popular frameworks.
                                Leverage Intel&reg; Gaudi&reg; AI accelerators
                                and RAG for better AI performance with less
                                hassle.
                            </h4>
                        </HeroCopy>
                        <SearchContent>
                            <SearchGroupComponent light={true} />
                            <OrCopy>or</OrCopy>
                            <CustomButton
                                arrow
                                styleType={'light'}
                                href="/explore"
                                text="See all"
                            ></CustomButton>
                        </SearchContent>
                    </HeroBladeContent>
                </HeroBladeWrapper>

                <CalloutsBlade>
                    <h2>Discover the AI Software Catalog</h2>
                    <p>
                        Choose from a broad range of solutions designed to
                        simplify and accelerate your AI development timeline,
                        from training and optimization to deployment. Our
                        catalog includes specialized tools and frameworks
                        optimized for RAG implementations, ensuring you have the
                        resources needed to build cutting-edge AI applications
                        that leverage the latest in retrieval-augmented
                        generation technology.
                    </p>
                    <ThreeCol>
                        <ColumnCard>
                            <Icon aria-label="An icon of a rocket ship in flight">
                                <Rocket />
                            </Icon>
                            <div>
                                <h3>Explore</h3>
                                <p>
                                    Learn about the latest open,
                                    deployment-ready solutions spanning multiple
                                    industries and use cases.
                                </p>
                            </div>
                        </ColumnCard>
                        <ColumnCard>
                            <Icon aria-label="An icon of a brain with circuitry, symbolizing AI">
                                <Cloud />
                            </Icon>
                            <div>
                                <h3>Experiment</h3>
                                <p>
                                    Put these innovative solutions to the test
                                    and find out what they’re capable of.
                                </p>
                            </div>
                        </ColumnCard>
                        <ColumnCard>
                            <Icon aria-label="An icon of several points expanding from a central point">
                                <Hexagon />
                            </Icon>
                            <div>
                                <h3>Deploy</h3>
                                <p>
                                    Scale solutions with Kubernetes
                                    orchestration, open APIs, and our unmatched
                                    partner ecosystem.
                                </p>
                            </div>
                        </ColumnCard>
                    </ThreeCol>
                </CalloutsBlade>

                <TrendingSolutions>
                    {/* DEBUG: Hide this if comparing to old modal functionality */}
                    <CardLinks
                        home={true}
                        title="Trending solutions"
                        queryType={'trending'}
                        count={5}
                    />
                    {/* DEBUG: Show this if comparing to old modal functionality */}
                    {/* <CardPanelsTemp home={true} title="Trending solutions" queryType={'trending'} isUserLoggedIn={isUserLoggedIn} count={5}/> */}

                    <CustomButton
                        href="/explore"
                        text="See all"
                        arrow
                        styleType="darkOutline"
                    />
                </TrendingSolutions>
                <CalloutsBlade>
                    <h2>Contributors</h2>
                    <ContributorsContent>
                        <a href="https://opea.dev/" target="_blank">
                            <img
                                src="/OPEA_Partner_logo_color.png"
                                alt="Powered by OPEA logo"
                            />
                        </a>
                    </ContributorsContent>
                </CalloutsBlade>
                {/* <DocumentationBlade>
                    <h2>Get started with documentation</h2>
                    <DocButton>
                        <Link to="/sdk">SDK</Link>
                    </DocButton>
                    <DocButton>
                        <Link to="/documentation">Documentation</Link>
                    </DocButton>
                    <DocButton>
                        <Link to="/code">Code Samples</Link>
                    </DocButton>
                    <DocButton>
                        <Link to="/github">GitHub</Link>
                    </DocButton>
                </DocumentationBlade> */}
            </>
        )
    }

    return (
        <ContainerWrapper home={true} hideSidePanel>
            {/* isUserLoggedIn passed as argument in child function from ContainerWrapper */}
            {(isUserLoggedIn) => bodyContent(isUserLoggedIn)}
        </ContainerWrapper>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {}
}

const mapStateToProps = (state: any) => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(MarketingContainer)
